import React, { useRef, useState } from 'react';
import './App.scss';
import { MainScreen } from './screens/MainScreen/MainScreen';
import { InfoScreen } from './screens/InfoScreen/InfoScreen';
import { ContactScreen } from './screens/ContactScreen/ContactScreen';
import { HumanCentered } from './screens/HumanCentered/HumanCentered';
import GlobalState, { defaultState } from './context/GlobalState';
import { init } from '@emailjs/browser';
import { TopNavigation } from './screens/TopNavigation/TopNavigation';
import { ServicesScreen } from './screens/ServicesScreen/ServicesScreen';
import { SectionWrapper, SectionWrapperContact, SectionWrapperNoHeight } from './App.styles';
import { Footer } from './components/Footer/Footer';

// homeRef:  React.RefObject<HTMLDivElement>, aboutRef:  React.RefObject<HTMLDivElement>, servicesRef:  React.RefObject<HTMLDivElement>, processRef:  React.RefObject<HTMLDivElement>
function App() {
    const homeRef = useRef<HTMLDivElement>(null);
    const aboutRef = useRef<HTMLDivElement>(null);
    const servicesRef = useRef<HTMLDivElement>(null);
    const humanCenteredRef = useRef<HTMLDivElement>(null);
    const processRef = useRef<HTMLDivElement>(null);
    const [lang, setLang] = useState(defaultState.lang);
    const [modalType, setModalOpen] = useState<string | null>(defaultState.modalType);

    return (
        <GlobalState.Provider value={{
            lang,
            modalType,
            setModalOpen,
            setLang
        }}>
            <div className="App">
                <TopNavigation
                    homeRef={homeRef}
                    aboutRef={aboutRef}
                    servicesRef={servicesRef}
                    processRef={processRef}
                    humanCenteredRef={humanCenteredRef}
                />
                {/* <SectionWrapper color='#FFEED9' ref={homeRef}> */}
                <MainScreen
                    homeRef={homeRef}
                    servicesRef={servicesRef}
                    aboutRef={aboutRef}
                    processRef={processRef}
                    humanCenteredRef={humanCenteredRef}
                />
                {/* </SectionWrapper> */}
                <SectionWrapperNoHeight color='#FFE5CC' ref={aboutRef}>
                    <InfoScreen />
                </SectionWrapperNoHeight>
                <SectionWrapper color='#FFEED9' ref={servicesRef}>
                    <ServicesScreen processRef={processRef} />
                </SectionWrapper>
                <SectionWrapper color='#FFE5CC' ref={humanCenteredRef}>
                    <HumanCentered processRef={processRef} />
                </SectionWrapper>
                <SectionWrapperContact color='#FFEED9' ref={processRef}>
                    <ContactScreen />
                </SectionWrapperContact>
                <Footer
                    homeRef={homeRef}
                    servicesRef={servicesRef}
                    aboutRef={aboutRef}
                    processRef={processRef}
                    humanCenteredRef={humanCenteredRef}
                />
            </div>
        </GlobalState.Provider>
    );
}

export default App;
