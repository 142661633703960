import styled from "styled-components";
import Services from "../../assets/Services.png"
import PolygonDoubled from "../../assets/polygons/PolygonDoubled.svg"
import PolygonDoubled2 from "../../assets/polygons/PolygonDoubled2.svg"
import { Button } from "../../components/CommonComponents.style";

export const PolygonTop = styled('div')`
    width: 70px;
    height: 70px;
    position: absolute;
  
    background: url(${PolygonDoubled2});
    background-repeat: no-repeat;
    background-size: cover;
    top: 2%;
    right: 6%;
    @media (min-width: 1200px) {
        top: 25%;
        right: 6%;
        bottom: unset;
        left: unset;
      
    }

    @media (min-width: 1600px) {
        top: 25%;
        right: 16%;
        bottom: unset;
        left: unset;
      
    }
`;
export const PolygonBottom = styled('div')`
    width: 70px;
    height: 70px;
    position: absolute;
   
    background: url(${PolygonDoubled});
    background-repeat: no-repeat;
    background-size: cover;

    top: 25%;
    left: 10%;
   

    @media (min-width: 1200px) {
        bottom: 10%;
        top: unset;
        left: unset;
        right: 40%;
    }

    @media (min-width: 1600px) {
        bottom: 10%;
        top: unset;
        left: unset;
        right: 60%;
      
    }

`;

export const ServicesWrapper = styled.div`
    background-color: #FFE5CC;
    position: relative;
`;

export const ServicesImage = styled.div`
    background: url(${Services});
    height: 299px;
    width: 299px;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 1200px) {
        height: 497px;
        width: 497px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 56px;
    padding-bottom: 88px;
`;

export const DescritpionDetails = styled.div`
    font-size: 1em;
    line-height: 1.5;
    text-align: left;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 56px;
    max-width: 800px;

    @media (min-width: 1200px) {
       max-width: 850px;
       font-size: 1.25em;
       line-height: 1.6;
       font-weight: 500;
       margin-bottom: 36px;
    }
`;

export const DesktopImageWrapper = styled.div`
    margin-left: 109px;

    @media (min-width: 1600px) {
        margin-left: 25%;
    }
`;

export const ServicesItemsWrapper = styled.div`
      @media (min-width: 1200px) {
        display: flex;

    }  
`;

export const DescriptionTitle = styled.div`
    font-size: 2em;
    font-weight: 700;
    line-height: 1.25;
    text-align: left;
    font-family: 'Poppins', sans-serif;

    @media (min-width: 1200px) {
        font-size: 3.375em;
        line-height: 1.29;
        /* padding-top: 112px; */
    }

`;


export const ServiceTitle = styled.div`
    font-weight: 700;
    font-size: 1.25em;
    line-height: 1.4;
    margin-bottom: 20px;
    color: #E83F3B;
   
`;

export const ServiceContent = styled.div`
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.42;
    margin-bottom: 40px;

    max-width: 380px;

    @media (min-width: 1200px) {
      max-width: 491px;
    }

`;

export const ServiceWrapper = styled.div`
    text-align: initial;

`;

export const StartProjectButton = styled(Button)`
    background-color: #E83F3B;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    max-width: 232px;
`;

export const HumanCenteredWrapper = styled.div`
    width: 100%;
    position: relative;
    margin: 0 32px 56px 32px;

    @media (min-width: 768px) {
        margin: 0 64px 56px 64px;
    }

    @media (min-width: 1200px) {
        margin: 112px 119px 214px 222px ;
        /* padding-bottom: 214px; */

    }
`;

export const ServicesInfoWrapper = styled.div`
    width: 100%;
    /* margin: 0 32px; */
    /* padding-bottom: 56px; */


    @media (min-width: 1200px) {
        /* margin: 0 112px; */
        /* padding-bottom: 214px; */

    }
`;

export const DescriptionWrapper = styled.div`
    flex: 1 1 0%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;


    @media (min-width: 1200px) {
        justify-content: center;
        /* padding-top: 112px; */
        
    }
`;