import styled, { css } from "styled-components";
import { TickIcon } from "../../assets/icons/TickIcon";
import { Button, ButtonType } from "../CommonComponents.style";



interface WrappersType {
    step: string;
}

interface InputType {
    error?: boolean;
}

interface IsSentType {
    isSent: boolean;
    step: string;
}

interface LabelType {
    isDisabled?: boolean;
}

export const Wrapper = styled.div`
    width: 100%;
    min-height: 612px;
    position: relative;

    @media (min-width: 1200px) {
        min-height: 650px;
    }
`;

export const Input = styled.input<InputType>`
    border: 1px solid #19191B;
    border-radius: 4px;
    margin-bottom: 24px;
    background-color: #FFEED9;
    padding: 15px 10px;
    width: calc(100% - 20px);
    ${({ error }) => error === true ? 'border-color: #E83F3B' : ''};

    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px #6200FF;
    }

    &:disabled {
        opacity: 0.64;
        border-color: rgba(0, 0, 0, 0.64);
    }

`;

export const Label = styled.div<LabelType>`
    background-color: #FFEED9;
    padding: 0 8px;
    font-size: 0.875em;
    line-height: 1.42;
    position: absolute;
    left: 16px;
    top: -9px;

    ${({ isDisabled }) => isDisabled === true ? 'color: #696969; z-index: 2;' : ''}
`;
export const StepOneTitle = styled.div`
    font-weight: 700;
    font-size: 1.25em;
    line-height: 1.4;
    margin-bottom: 24px;
    color: #E83F3B;
    text-align: left;
`;

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
`;

export const SentInfo = styled.div`
    background-color: #FCC800;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 12px 0;
    margin-bottom: 20px;
    width: 100%;
    max-width: 500px;
`;

export const TickIconWrapper = styled.div`
    margin-left: 12px;
`;

export const SentText = styled.div`
    font-weight: 700;
    margin-left: 8px;
`;



export const NextStepButton = styled(Button) <ButtonType>`
    width: auto;
    &:hover {
        background-color: #E83F3B;
        color: #fff;
        transition: 0.6s;
        ${({ isValid }) => isValid ? 'cursor: pointer' : 'opacity: 0.6; cursor: not-allowed'};
    }

    @media (min-width: 1200px) {
        max-width: 370px;
    }

`;

export const SubmitButton = styled(Button) <ButtonType>`
    background-color: #E83F3B;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    ${({ isValid }) => isValid ? 'cursor: pointer' : 'opacity: 0.6; cursor: not-allowed'};
`;

export const StepOneWrapper = styled.div<WrappersType>`
    width: 100%;
  
    @media (min-width: 1200px) {
        max-width: 390px;
        position: absolute; 
        top: 40px;
    }

    ${({ step }) => step === "second" ? `visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;`
        : ` visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;`  }
`;

export const StepTwoWrapper = styled.div<WrappersType>`
    position: absolute;
    top:0;
    width: 100%;
    @media (min-width: 1200px) {
        width: 100%;
        top: 40px;
        max-width: 390px;
    }

    
    ${({ step }) => step === "second" ? 'visibility: visible; opacity: 1; transition-delay: 0s;' : 'visibility: hidden; opacity: 0; transition: visibility 0s linear 0.33s, opacity 0.33s linear;'}
`;

export const TextArea = styled.textarea`
    padding: 15px 10px;
    background-color: #FFEED9;
    height: 296px;
    margin-bottom: 24px;
    width: calc(100% - 20px);

    &:disabled {
        opacity: 0.64;
        border-color: rgba(0, 0, 0, 0.64);
    }
`;

export const ButtonsWrapper = styled.div<IsSentType>`
    display: ${({ step }) => step === 'first' ? 'none' : 'flex'};
    ${({ isSent }) => isSent ? 'visibility: hidden; opacity: 0; transition: visibility 0s linear 0.33s, opacity 0.33s linear;' : 'visibility: visible; opacity: 1; transition-delay: 0s;'}
`;

export const SentButtonsWrapper = styled.div<IsSentType>`
    display: ${({ step }) => step === 'first' ? 'none' : 'flex'};
    flex-direction: column;
    width: calc(100% - 24px);
    position: absolute;
    top: 480px;
    width: 100%;
    align-items: center;

    @media (min-width: 1200px) {
        max-width: none;
    }

    ${({ isSent }) => isSent ? 'visibility: visible; opacity: 1; transition-delay: 0s;' : 'visibility: hidden; opacity: 0; transition: visibility 0s linear 0.33s, opacity 0.33s linear;'}
`;

export const ErrorMessage = styled.div`
    margin-top: 8px;
    line-height: 1.5;
    font-size: 0.875em;
    color: #E83F3B;
    font-weight: 500;
    font-family: 'Poppins',sans-serif;
    text-align: left;
`;

export const NextProjectButton = styled(Button)`
    background-color: #E83F3B;
    color: #fff;
    width: 100%;
    padding: 14px 0;
    max-width:500px;
    border: unset;
`;
