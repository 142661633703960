import React from 'react'; // we need this to make JSX compile
import { Home } from '../../components/Home/Home';
import { MainScreenWrapper } from './MainScreen.style';

export interface MainScreenType {
    homeRef: React.RefObject<HTMLDivElement>,
    aboutRef: React.RefObject<HTMLDivElement>,
    servicesRef: React.RefObject<HTMLDivElement>,
    processRef: React.RefObject<HTMLDivElement>,
    humanCenteredRef: React.RefObject<HTMLDivElement>,
}

export const MainScreen = ({ aboutRef }: MainScreenType) => {
    return (
        <MainScreenWrapper>
            <Home aboutRef={aboutRef} />
        </MainScreenWrapper>

    )
} 