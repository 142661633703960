import styled from "styled-components";
import Bg390 from "../../assets/backgrounds/bg-390.png"
import Bg414 from "../../assets/backgrounds/bg-414.png"
import Bg820 from "../../assets/backgrounds/bg-820.png"
import Bg1440 from "../../assets/backgrounds/bg-1440.png"

export const MainScreenWrapper = styled.div`
  

 
`;

export const PersonDescription = styled.div`
   width: 50%;
`;

export const Wrapper = styled.div`

`;
export const PlusElem = styled('div') <{ color?: string }>`
    padding: 15px 15px;
    height: 5px;
    width: 5px;

    position: relative;

    ::before, ::after {
        content: " ";
        width: 13px;
        height: 2px;
        background-color: ${({ color }) => color ?? 'rgba(210, 195, 195, 0.5)'};
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: all 0.15s cubic-bezier(.42, 0, .58, 1);
        opacity: 1;
        border-radius: 2px;
    }

    ::before {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    ::after {
        transform: translate(-50%, -50%);
    }

    @media (min-width: 768px) {
        height: 5px;
        width: 5px;
        padding: 15px 13px;
    }
`

export const TopPatternWrapper = styled('div')`
    margin: 23px 16px;
`
export const TopPatternRow = styled('div')`
    display: flex;
    justify-content: space-between;
`
export const TopPatternCol = styled('div') <{ side: 'left' | 'right' }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${({ side }) => side === 'left' ? 'flex-start' : 'flex-end'};
`
