import styled from "styled-components";
import Team from "../../assets/Team.png"
import TeamMobile from "../../assets/TeamMobile.png"

interface SectionTitle {
    themeDark: boolean;
}

export const InfoScreenWrapper = styled.div<{width: number}>`
    position: relative;
    padding: 56px 0;
    width: 100%;

    background: repeating-linear-gradient(
        to right,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0.05) 1px,
        #FFE5CC 1px,
        #FFE5CC ${({width}) => width}px
      );
`;

export const TeamImage = styled.div`
    background: url(${TeamMobile});
    min-height: 200px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 16px;
    background-position: center;

    @media (min-width:  415px) {
        min-height: 350px;
   }


    @media (min-width:  992px) {
        min-height: 400px;
   }

    @media (min-width: 1100px) {
        min-height: 500px;
        margin: 0 120px;
        background: url(${Team});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
   }
`;


export const Row = styled.div`
   display: flex;
   padding: 20px auto;
   flex-direction: column;

   @media (min-width: 768px) {
        flex-direction: row;
   }

`;

export const SectionTitle = styled.div<SectionTitle>`
    padding-top: 20px;
    margin-left: 30px;
    position: absolute;
    font-size: 1.375em;
    text-decoration: underline;
    color: ${({ themeDark }) => themeDark ? '#fff' : '#000'};
`;

export const DescriptionsWrapper = styled.div`
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    max-width: 870px;
    margin: 0 32px;
    padding-bottom: 20px;

    @media (min-width: 768px) {
        justify-content: center;
        margin: auto;
    }
`;

export const DescriptionTitle = styled.div`
    font-size: 2em;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;

    @media (min-width: 768px) {
        font-size: 3.375em;
        line-height: 1.29;
    }

`;

export const Header = styled.div`
    max-width: 600px;
    margin: 0 auto 32px auto;
    
`;

export const SubTitle = styled.div`
    font-size: 1em;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
    

    @media (min-width: 768px) {
        font-size: 1.25em;
        line-height: 1.6;
    }
`;

export const DescriptionSpan = styled.span`
    color: #E83F3B;
`;