import React from 'react'; // we need this to make JSX compile
import { ServicesListScreen } from '../ServicesListScreen/ServicesListScreen';
interface ServicesScreenType {
    processRef: React.RefObject<HTMLDivElement>
}

export const ServicesScreen = ({ processRef }: ServicesScreenType) => {
    
    return (
        <div>
            <ServicesListScreen />
        </div>

    )

}
