import * as React from 'react';
import styled from 'styled-components';

const SvgElement = styled.svg`
    height: auto;
`;

interface IconPropsType {
    color: string;
}

export const TickIcon = (props: IconPropsType): JSX.Element => {
    return (
        <SvgElement
            clipRule='evenodd'
            fill={props.color}
            fillRule='evenodd'
            height='24'
            strokeLinejoin='round'
            strokeMiterlimit='2'
            viewBox='0 0 24 24'
            width='24'
        >
            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#19191B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="0.72" d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#19191B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgElement>
    );
};
