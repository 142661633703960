import styled, { keyframes } from "styled-components";
import AnimatedText from 'react-animated-text-content';
import Bg390 from "../../assets/backgrounds/bg-390.png"
import Bg414 from "../../assets/backgrounds/bg-414.png"
import Bg820 from "../../assets/backgrounds/bg-820.png"
import Bg1440 from "../../assets/backgrounds/bg-1440.png"


interface ClientType {
    fadeIn: boolean;
    show: boolean;
}

interface AnimatedTextType {
    show: boolean;
}

export const HomeText = styled.div`
    max-width: 900px;
    text-align: center;
    color: #19191B;
    font-size: 2.375em;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;

    @media (min-width: 768px) {
        font-size: 4em;
        line-height: 1.29;
    }
`;

export const HomeWrapper = styled.div`
    display: flex;
    min-height: calc( 100vh - 56px );
    flex-direction: column;
    overflow: hidden;
    position: relative;    
    padding-top: 56px;
    background-repeat: no-repeat;

    background-image: url(${Bg390});

    width: 100%;
    min-height: calc(100vh - 56px);
    @media (min-width: 414px) {
        background-image: url(${Bg414});
   }

   @media (min-width: 992px) {
        padding-top: 100px;
        min-height: 100vh;
   }
   @media (min-width: 820px) {
    background-image: url(${Bg820});
   }

   @media (min-width: 1200px) {
       padding-top: 0;
   }
    

   @media (min-width: 1440px) {
        background-image: url(${Bg1440});
   }
   background-size: 100% 100%;
`;

export const HomepageContentWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    max-width: 311px;

    @media (min-width: 768px) {
        max-width: none;
        justify-content: center;
        margin: 0 32px 120px 32px;
    }

    @media (min-height: 1200px) and (min-width: 1100px){
        font-size: 25px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
   
`;


export const RedText = styled.span`
    color: #E83F3B;
    
    @media (min-width: 768px) {
        text-transform: uppercase;
    }
`;

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const ScrollWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;

	width: 34px;
	height: 55px;
    cursor: pointer;

    animation: ${fadeIn} 5s;

    @media (min-height: 1150px){
        width: 60px;
	    height: 90px;
    }
   
`;

export const ButtonWrapper = styled('div')`
    font-family: 'Poppins';
    background-color: #E83F3B;
    border-radius: 4px;
    color: #fff;
    padding: 14px 70px;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 28px;
    margin-top: 56px;
    z-index: 2;
    cursor: pointer;

    animation: ${fadeIn} 5s;

  
    @media (min-height: 1150px){
        font-size: 2.0em;
        padding: 30px 140px;
    }
`;

export const TopPatternWrapperMobile = styled('div')`
    display: block;
    @media (min-width: 768px) {
        display: none;
    }
`
export const TopPatternWrapperDesktop = styled('div')`
    display: none;
    @media (min-width: 768px) {
        display: block;
        padding: 100px 109px 0 109px;
    }
`

export const RestOfTitleWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PlaceholderTitle = styled('div')`
   /* width: 900px; */
    text-align: center;
    color: transparent;
    font-size: 2.375em;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;

    @media (min-width: 768px) {
        font-size: 4em;
        line-height: 1.29;
    }   
`;
export const AnimatedTextWrapper = styled(AnimatedText)`
    /* width: 900px; */
    text-align: center;
    color: #19191B;
    font-size: 2.375em;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;

    @media (min-width: 768px) {
        font-size: 4em;
        line-height: 1.29;
    }   
`;

export const AnimatedTextWrapperPlaceholder = styled(AnimatedText)`
    /* width: 900px; */
    text-align: center;
    color: transparent;
    font-size: 1px;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;

    @media (min-width: 768px) {
        font-size: 4em;
        line-height: 1.29;
    }   

`;

export const AnimatedTextWrapperLast = styled(AnimatedText) <AnimatedTextType>`
    /* width: 900px; */
    text-align: center;
    color: #19191B;
    font-size: 2.375em;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    opacity: 0;
    ${({ show }) => show ? 'opacity: 1; transition: opacity 1s ease;' : ''}

    @media (min-width: 768px) {
        font-size: 4em;
        line-height: 1.29;
    }   
`;

export const Client = styled('div') <ClientType>`
    // width: 900px;
    text-align: center;
    font-size: 2.375em;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;


    @media (min-width: 768px) {
        font-size: 4em;
        line-height: 1.29;
    }   

    color: #E83F3B;

    @media (min-width: 768px) {
        text-transform: uppercase;
    }

    ${({ fadeIn, show }) => show === false ? 'opacity: 0;' : fadeIn ? 'transition: opacity 1.5s ease;' : 'opacity: 0; transition: opacity 1.5s ease;'}

`

export const ClientPlaceholder = styled('div')`
     /* width: 900px; */
    text-align: center;
    font-size: 2.375em;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;


    @media (min-width: 768px) {
        font-size: 4em;
        line-height: 1.29;
    }   

    color: transparent;

    @media (min-width: 768px) {
        text-transform: uppercase;
    }
`;

export const AnimatedTextWrapperRed = styled(AnimatedText)`
    /* width: 900px; */
    text-align: center;
    font-size: 2.375em;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;


    @media (min-width: 768px) {
        font-size: 4em;
        line-height: 1.29;
    }   

    color: #E83F3B;
    
    @media (min-width: 768px) {
        text-transform: uppercase;
    }
`;


export const MouseScrollIconWrapper = styled("div")`
    position: absolute;
    bottom: 100px;
    left: 0;
    width: 41px;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
        bottom: 70px;
    }

    @media (min-height: 1150px){
        width: 81px;
        bottom: 100px;
    }
`;