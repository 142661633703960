import { PlusElem, TopPatternWrapper, TopPatternRow, TopPatternCol } from './MainScreen.style';

export const TopPatternMobile = () => {
    return (
        <TopPatternWrapper>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem color='#6246E5' />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>
                <TopPatternCol side='right'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem color='#FF7152' />
                    <PlusElem />
                </TopPatternCol>
            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                </TopPatternCol>
                <TopPatternCol side='right'>
                    <PlusElem />
                </TopPatternCol>
            </TopPatternRow>
        </TopPatternWrapper>
    )
}
export const TopPatternDesktop = () => {
    return (
        <TopPatternWrapper>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem color='#FF7152' />
                </TopPatternCol>
                <TopPatternCol side='right'>
                    <PlusElem color='#ffeed9' />
                    <PlusElem color='#ffeed9' />
                    <PlusElem color='#ffeed9' />
                    <PlusElem color='#ffeed9' />
                    <PlusElem />
                </TopPatternCol>
            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>
                <TopPatternCol side='right'>
                    <PlusElem color='#ffeed9' />
                    <PlusElem color='#ffeed9' />
                    <PlusElem color='#ffeed9' />
                    <PlusElem />
                    <PlusElem color='#ffeed9' />
                </TopPatternCol>
            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem color='#6246E5' />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>
                <TopPatternCol side='right'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem color='#6246E5' />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>
            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem color='#ffeed9' />
                    <PlusElem />
                    <PlusElem color='#ffeed9' />
                    <PlusElem color='#ffeed9' />
                    <PlusElem color='#ffeed9' />
                </TopPatternCol>
                <TopPatternCol side='right'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>
            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                </TopPatternCol>
                <TopPatternCol side='right'>
                    <PlusElem color='#FF7152' />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>
            </TopPatternRow>
        </TopPatternWrapper>
    )
}

export const TopPatternContact = () => {
    return (
        <TopPatternWrapper>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem color='#FF7152' />
                </TopPatternCol>

            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>

            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem color='#6246E5' />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>

            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem color='#ffeed9' />
                    <PlusElem />
                    <PlusElem color='#ffeed9' />
                    <PlusElem color='#ffeed9' />
                </TopPatternCol>

            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                </TopPatternCol>

            </TopPatternRow>
        </TopPatternWrapper>
    )
};

export const TopPatternContactMobile = () => {
    return (
        <TopPatternWrapper>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem color='#FF7152' />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>

            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                    <PlusElem />
                </TopPatternCol>

            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />
                    <PlusElem />
                    <PlusElem color='#6246E5' />

                    <PlusElem />
                </TopPatternCol>

            </TopPatternRow>
            <TopPatternRow>
                <TopPatternCol side='left'>
                    <PlusElem />

                </TopPatternCol>

            </TopPatternRow>
        </TopPatternWrapper>
    )
};