import { CameraIcon } from "../../../assets/icons/CameraIcon";
import { StackIcon } from "../../../assets/icons/StackIcon";
import { CodeIconWrapper, Description, ImageContainer, Title, Wrapper } from "./Service.style";

interface ServiceType {
    title: string;
    description: string;
    image: string;
}

export const Service = ({ title, description, image }: ServiceType) => (
    <Wrapper>
        <ImageContainer>
            {image === 'camera' ?
            <CameraIcon color="#FFE5CC" /> : image === 'code' ? 
            <StackIcon color="#FFE5CC" /> : 
            <CodeIconWrapper  />}
        </ImageContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
    </Wrapper>
)
