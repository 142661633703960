import styled from "styled-components";
import CodeIcon from "../../../assets/icons/code.png";

export const ServicesWrapper = styled.div`
    background-color: #FFEED9;
    position: relative;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
    max-width: 320px;


    @media (min-width: 768px) {
        margin-top: 24px;
        margin-right: 10px;
    }
`;

export const CodeIconWrapper = styled.div`
    background: url(${CodeIcon});
    width: 32px;
    height: 32px;
`;

export const ImageContainer = styled.div`
    width: 69px;
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #FFE5CC;
    margin-bottom: 16px;

`
export const Title = styled.div`
    font-weight: 700;
    font-size: 1.25em;
    line-height: 1.4;
    color: rgba(0, 0, 0, 0.64);
    margin-bottom: 20px;
    text-align: center;
`;

export const Description = styled.div`
    font-size: 0.875em;
    line-height: 1.42;
    max-width: 300px;
    color: #201E1B;
    font-weight: 700;
    text-align: center;
`;
