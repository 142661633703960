import styled from "styled-components";
import Bg390 from "./assets/backgrounds/bg-390.png"
import Bg414 from "./assets/backgrounds/bg-414.png"
import Bg820 from "./assets/backgrounds/bg-820.png"
import Bg1440 from "./assets/backgrounds/bg-1440.png"


export const SectionWrapper = styled('div') <{ color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // min-height: calc( 100vh - 56px );
    background-color: ${({ color }) => color};

    @media (min-width: 992px) {
        // min-height: calc( 100vh - 100px );

    }
`;

export const SectionWrapperNoHeight = styled('div') <{ color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    background-color: ${({ color }) => color};
`;

export const SectionWrapperContact = styled('div') <{ color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${({ color }) => color};
    background-image: url(${Bg390});

    @media (min-width: 414px) {
        background-image: url(${Bg414});
   }
   @media (min-width: 820px) {
    background-image: url(${Bg820});
   }
    
   @media (min-width: 1440px) {
        background-image: url(${Bg1440});
   }

   background-size: 100% 100%;

    
 
`;


// @media (min-width: 1100px) {
//     background: url(${Contact});
//     background-repeat: no-repeat;  
//     background-position: 0% 0%;
//     background-size: 100% 100%;
// }
