import { ContactForm } from '../../components/ContactForm/ContactForm';
import useWindowDimensions from '../../context/windowDimensions/WindowDimensions';
import { TopPatternContact, TopPatternContactMobile } from '../MainScreen/TopPattern';

import { ContactScreenWrapper, DescriptionSpan, DescriptionTitle, DescriptionWrapper, DescritpionDetails, EmailTxt, EmailWrapper, LetsGoToWork, MovementWrapper, PatternWrapper, Row, Wrapper } from './ContactScreen.style';


export const ContactScreen = () => {
  const { width } = useWindowDimensions();

  const isTabletOrWider = (width >= 1200);

  return (

    <Wrapper>
      <PatternWrapper>
        {isTabletOrWider ?
          <TopPatternContact /> :
          <TopPatternContactMobile />}
      </PatternWrapper>
      <div className="particle elem1 anim-delay1"></div>
      <div className="particle elem2 anim-delay5"></div>
      <div className="particle elem3 anim-delay3"></div>
      <div className="particle elem4 anim-delay4"></div>
      <div className="particle elem5 anim-delay6"></div>
      <div className="particle elem6 anim-delay9"></div>
      <div className="particle elem7 anim-delay8"></div>
      <div className="particle elem8 anim-delay1"></div>
      <DescriptionWrapper>
        <DescriptionTitle>Start your<DescriptionSpan> next project.</DescriptionSpan> </DescriptionTitle>
        <DescritpionDetails>No matter the size of project, we can help.
          Fill out our form and we will get back to you ASAP - even if it’s out of office hours we are always on.

          <LetsGoToWork>
            Let’s get to work!
          </LetsGoToWork>
        </DescritpionDetails>
      </DescriptionWrapper>
      <ContactScreenWrapper>
        <ContactForm />
      </ContactScreenWrapper>

    </Wrapper>


  )

}
