import styled from "styled-components";

export const ContactScreenWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 56px 24px 10px 24px;
  

    flex-direction: column-reverse;
   
    position: relative;
    flex: 1 1 0%;

    @media (min-width: 768px) {
        padding: 56px 64px 10px 64px;
    }


    @media (min-width: 1200px) {
        max-width: 390px;
        margin: 0 auto;
        background: none;
        padding: 0;
    }

    @media (min-width: 1600px) {
        max-width: none;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
  
`;

export const Row = styled.div`
   display: flex;
   padding: 20px auto;

`;

export const DescriptionWrapper = styled.div`
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 32px;

    @media (min-width: 768px) {
        margin: 0 64px;
        /* margin-left: 20px; */
    }

    @media (min-width: 1200px) {
        flex: 1 1 0%;

        justify-content: center;
        margin: auto;
        margin-top: 200px;
        margin-right: 20px;


    }

`;

export const Wrapper = styled.div`
    /* height: calc(100vh - 180px); */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 180px;

    @media (min-width: 1200px) {
        padding: 0 203px 160px 213px;
        flex-direction: row;
        /* height: 100vh; */
    }
	overflow: hidden;
    position: relative;
`;

export const MovementWrapper = styled.div`
	height: calc(100vh - 180px);
	overflow: hidden;
    position: absolute;
	width: 100%;
	margin: 0 auto;
	background: transparent;
	position: relative;
`;

export const AdditionalInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 80px;

    width: 100%;
    margin-bottom: 20px;

    @media (min-width: 1200px) {
        margin-top: -80px;
        width: auto;
        margin-left: 50px
    }
`;

export const DescritpionDetails = styled.div`
    margin-top: 8px;
    font-size: 1.25em;
    line-height: 1.6;
    text-align: left;
    padding: 0 32px 0 0;
    font-weight: 500;
    max-width: 700px;

    @media (min-width: 1200px) {
       padding: 0;
       margin-top: 6px;
       width: 435px;
       max-width: none;
    }
`;

export const LetsGoToWork = styled.div`
    font-weight: 700;
`;

export const DescriptionTitle = styled.div`
    font-size: 2em;
    font-weight: 700;
    line-height: 1.25;
    max-width: 240px;
    /* margin-left: 32px; */
    text-align: left;
    font-family: 'Poppins', sans-serif;

    @media (min-width: 1200px) {
        font-size: 3.375em;
        max-width: 370px;
        line-height: 1.29;
    }
`;

export const DescriptionSpan = styled.span`
    color: #E83F3B;
`;

export const AdditionalInfo = styled.div`
    color: #fff;
    display: flex;
    align-items: flex-end;
    margin-top: 8px;
    font-size: 1em;
`;

export const EmailWrapper = styled.div`
  display: flex;
    align-items: center;
   
    color: #fff;
    @media (min-width: 1200px) {
        display: flex;
    }
`;

export const EmailTxt = styled.div`
    font-weight: bold;
    margin-left: 5px;
`

export const PatternWrapper = styled.div`
    /* height: 180px; */
    /* width: 100%; */
    position: absolute;
    top: 12px;
    left: 10px;

    @media (min-width: 768px) {
        top: 12px;
        left: 48px;
    }

    @media (min-width: 1200px) {
        top: 62px;
        left: 83px;
    }
`;