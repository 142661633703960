import React from 'react'; // we need this to 
import { MouseScrollIcon } from '../../assets/icons/MouseScrollIcon';
import { TopPatternDesktop, TopPatternMobile } from '../../screens/MainScreen/TopPattern';
import { AnimatedText } from '../AnimatedText/AnimatedText';
import { HomeWrapper, HomeText, RedText, ButtonWrapper, HomepageContentWrapper, TopPatternWrapperMobile, TopPatternWrapperDesktop, AnimatedTextWrapper, AnimatedTextWrapperRed, RestOfTitleWrapper, MouseScrollIconWrapper, ScrollWrapper } from './Home.style';

interface PropsTypes {
    aboutRef: React.RefObject<HTMLDivElement>,
}

export const Home = ({ aboutRef }: PropsTypes) => {
    const width = window.innerWidth
    const isTabletOrWider = (width >= 992);

    const goToAboutSection = () => {
        const offsetTop = aboutRef?.current?.offsetTop ?? 100;
        const isTabletOrWider = (width >= 992);
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
    }
    return (
        <HomeWrapper>
            <div className="particle elem1 anim-delay1"></div>
            <div className="particle elem2 anim-delay5"></div>
            <div className="particle elem3 anim-delay3"></div>
            <div className="particle elem4 anim-delay4"></div>
            <div className="particle elem5 anim-delay6"></div>
            <div className="particle elem6 anim-delay9"></div>
            <div className="particle elem7 anim-delay8"></div>
            <div className="particle elem8 anim-delay1"></div>

            <TopPatternWrapperMobile>
                <TopPatternMobile />
            </TopPatternWrapperMobile>
            <TopPatternWrapperDesktop>
                <TopPatternDesktop />
            </TopPatternWrapperDesktop>
            <HomepageContentWrapper>
                <AnimatedText />
                <ButtonWrapper onClick={goToAboutSection}>Explore</ButtonWrapper>
            </HomepageContentWrapper>
            <MouseScrollIconWrapper onClick={goToAboutSection}>
                <ScrollWrapper>
                    <div className="mousey">
                        <div className="scroller"></div>
                    </div>
                </ScrollWrapper>

            </MouseScrollIconWrapper>

        </HomeWrapper>
    )
}




