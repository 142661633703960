import React, { ReactText, useContext, useEffect, useRef, useState } from 'react'; // we need this to 
import AnimatedText from 'react-animated-text-content';
import { AnimatedTextWrapperRed, Client, ClientPlaceholder } from '../Home.style';

interface PropsTypes {
    aboutRef: React.RefObject<HTMLDivElement>,
}

// BUSINESSES PEOPLE COMPANIES
export const ClientType = () => {
    const [iterator, setIterator] = useState(-1);
    const [show, setShow] = useState(false);
    const [time, setTime] = useState(200);
    const [fade, setFade] = useState('out');
    const [client, setClient] = useState<ReactText>("FOUNDERS");
    const clientsArray = ["FOUNDERS", "BUSINESSES", "PEOPLE", "COMPANIES"];

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, time + 500);

        const timeout = setInterval(() => {

            if (fade === 'in') {
                setFade('out');

            } else {
                if (time === 200) {
                    setTime(2000)
                }
                setIterator(previousValue => previousValue + 1);

                setFade('in');
            }
        }, time);

        return () => clearInterval(timeout)
        // return () => clearTimeout(timer);



    }, [fade])

    const i = iterator % 4;

    return (
        <Client show={show} fadeIn={fade === 'in'}>{clientsArray[i]}</Client>
    )
}