import * as React from 'react';
import styled from 'styled-components';

const SvgElement = styled.svg`
    height: auto;
`;

interface IconPropsType {
    color: string;
}

export const CameraIcon = (props: IconPropsType): JSX.Element => {
    return (
        <SvgElement
            clipRule='evenodd'
            fill={props.color}
            fillRule='evenodd'
            strokeLinejoin='round'
            strokeMiterlimit='2'
            width="32"
            height="32"
            viewBox="0 0 32 32" 
        >
            <path d="M9.01323 29.3334H22.9866C26.6666 29.3334 28.1332 27.0801 28.3066 24.3334L28.9999 13.3201C29.1866 10.4401 26.8932 8.00008 23.9999 8.00008C23.1866 8.00008 22.4399 7.53342 22.0666 6.81342L21.1066 4.88008C20.4932 3.66675 18.8932 2.66675 17.5332 2.66675H14.4799C13.1066 2.66675 11.5066 3.66675 10.8932 4.88008L9.93323 6.81342C9.5599 7.53342 8.81323 8.00008 7.9999 8.00008C5.10656 8.00008 2.81323 10.4401 2.9999 13.3201L3.69323 24.3334C3.85323 27.0801 5.33323 29.3334 9.01323 29.3334Z" stroke="#5454D4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 10.6667H18" stroke="#5454D4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.9998 23.9999C18.3865 23.9999 20.3332 22.0533 20.3332 19.6666C20.3332 17.2799 18.3865 15.3333 15.9998 15.3333C13.6132 15.3333 11.6665 17.2799 11.6665 19.6666C11.6665 22.0533 13.6132 23.9999 15.9998 23.9999Z" stroke="#5454D4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgElement>
    );
};
