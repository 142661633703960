import useWindowDimensions from "../../context/windowDimensions/WindowDimensions";
import { Address, AddressWrapper, GeoIcon, LineSeparator, Link, Links, LogoWrapper, Row, Wrapper } from "./Footer.style"

export interface FooterType {
    homeRef: React.RefObject<HTMLDivElement>,
    aboutRef: React.RefObject<HTMLDivElement>,
    servicesRef: React.RefObject<HTMLDivElement>,
    processRef: React.RefObject<HTMLDivElement>,
    humanCenteredRef: React.RefObject<HTMLDivElement>,
}

export const Footer = ({ homeRef, aboutRef, servicesRef, processRef, humanCenteredRef }: FooterType) => {
    const { width } = useWindowDimensions();

    const isTabletOrWider = (width >= 992);

    const goToHomeSection = () => {
        const offsetTop = homeRef?.current?.offsetTop ?? 100;
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
    }
    const goToAboutSection = () => {
        const offsetTop = aboutRef?.current?.offsetTop ?? 100;
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
    }
    const goToServicesSection = () => {
        const offsetTop = servicesRef?.current?.offsetTop ?? 100;
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
    }
    const goToHumanCenteredSection = () => {
        const offsetTop = humanCenteredRef?.current?.offsetTop ?? 100;
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
    }
    const goToProcessSection = () => {
        const offsetTop = processRef?.current?.offsetTop ?? 100;
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
    }

    return (
        <Wrapper>
            <Row isFirst={true}>
                <LogoWrapper />
                <Links>
                    <Link onClick={goToAboutSection}>About</Link>
                    <Link onClick={goToServicesSection}>Services</Link>
                    <Link onClick={goToHumanCenteredSection}>Our work</Link>
                    <Link onClick={goToProcessSection}>Contact</Link>
                </Links>
            </Row>
            <LineSeparator />
            <Row>
                <AddressWrapper>
                    <GeoIcon color="#fff" />
                    <Address>1 Poultry, London EC2R 8EJ, United Kingdom </Address>
                </AddressWrapper>
                <AddressWrapper>
                    <GeoIcon color="#fff" />
                    <Address>Rynek Główny 6, Kraków, Małopolskie, Poland</Address>
                </AddressWrapper>
            </Row>
        </Wrapper>
    )

}
