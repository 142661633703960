import * as React from 'react';
import styled from 'styled-components';

const SvgElement = styled.svg`
    height: auto;
`;

interface IconPropsType {
    color: string;
}

export const LocationIcon = (props: IconPropsType): JSX.Element => {
    return (
        <SvgElement
            clipRule='evenodd'
            fill="none"
            fillRule='evenodd'
            strokeLinejoin='round'
            strokeMiterlimit='2'
            width="12" height="12"
            viewBox="0 0 12 12"
        >
            <path opacity="0.4" d="M5.99994 6.71522C6.86151 6.71522 7.55994 6.01678 7.55994 5.15522C7.55994 4.29365 6.86151 3.59521 5.99994 3.59521C5.13838 3.59521 4.43994 4.29365 4.43994 5.15522C4.43994 6.01678 5.13838 6.71522 5.99994 6.71522Z" stroke="#292D32" strokeWidth="1.5" />
            <path d="M1.80985 4.245C2.79485 -0.0849988 9.20985 -0.0799987 10.1899 4.25C10.7649 6.79 9.18485 8.94 7.79985 10.27C6.79485 11.24 5.20485 11.24 4.19485 10.27C2.81485 8.94 1.23485 6.785 1.80985 4.245Z" stroke="#292D32" strokeWidth="1.5" />
        </SvgElement>
    );
};

