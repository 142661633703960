import * as React from 'react';
import styled from 'styled-components';

const SvgElement = styled.svg`
    height: auto;
`;

interface IconPropsType {
    color: string;
}

export const StackIcon = (props: IconPropsType): JSX.Element => {
    return (
        <SvgElement
            clipRule='evenodd'
            fill={props.color}
            fillRule='evenodd'
            strokeLinejoin='round'
            strokeMiterlimit='2'
            width="33" height="32" 
            viewBox="0 0 33 32"
        >
            <path d="M17.8469 3.89351L25.7135 7.38684C27.9802 8.38684 27.9802 10.0402 25.7135 11.0402L17.8469 14.5335C16.9535 14.9335 15.4869 14.9335 14.5935 14.5335L6.72686 11.0402C4.46019 10.0402 4.46019 8.38684 6.72686 7.38684L14.5935 3.89351C15.4869 3.49351 16.9535 3.49351 17.8469 3.89351Z" stroke="#F04037" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.5 14.6667C4.5 15.7867 5.34 17.0801 6.36667 17.5334L15.42 21.5601C16.1133 21.8667 16.9 21.8667 17.58 21.5601L26.6333 17.5334C27.66 17.0801 28.5 15.7867 28.5 14.6667" stroke="#F04037" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.5 21.3333C4.5 22.5733 5.23333 23.6933 6.36667 24.1999L15.42 28.2266C16.1133 28.5333 16.9 28.5333 17.58 28.2266L26.6333 24.1999C27.7667 23.6933 28.5 22.5733 28.5 21.3333" stroke="#F04037" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgElement>
    );
};
