import React from 'react';
import { DescriptionSpan } from '../ContactScreen/ContactScreen.style';
import { DescriptionTitle, DescriptionWrapper, DescritpionDetails, DesktopImageWrapper, HumanCenteredWrapper, ServiceContent, ServicesImage, Wrapper, ServicesItemsWrapper, ServiceTitle, ServiceWrapper, StartProjectButton, PolygonTop, PolygonBottom } from '../ServicesScreen/ServicesScreen.style';
import useWindowDimensions from '../../context/windowDimensions/WindowDimensions';


interface ServicesScreenType {
    processRef: React.RefObject<HTMLDivElement>
}

const services = [{
    title: 'Discovery',
    content: 'We take a collaborative approach throughout the discovery and ideation phase to validate your product idea, understand your goals and gather key deliverables for your products roadmap to get you live.'
},
{
    title: 'Design',
    content: 'All of the best mobile apps aim to solve a specific problem in the most elegant way possible. Our design team will look to curate the best user journeys through a series of workshops and prototypes in order to create a beautifully usable app for you and your users.'
},
{
    title: 'Development',
    content: 'We know that technology isn’t everyone’s forte. Once your product has been signed off after the discovery and design phase our team of developers will be able to swiftly bring your idea from ideation to production.'
}
];

export const HumanCentered = ({ processRef }: ServicesScreenType) => {
    const { width } = useWindowDimensions();
    const isTabletOrWider = (width >= 992);

    const goToProcessSection = () => {
        const offsetTop = processRef?.current?.offsetTop ?? 100;
        const isTabletOrWider = (width >= 992);
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
    }
    const isWideScreen = (width >= 1200);

    return (
        <HumanCenteredWrapper>
            <PolygonTop />
            <PolygonBottom />
            {isWideScreen ? null :
                <Wrapper>
                    <ServicesImage />

                </Wrapper>}
            <DescriptionWrapper>
                {isWideScreen ?
                    <DescriptionTitle><DescriptionSpan> Human-centered</DescriptionSpan> <div>mobile and web solutions.</div> </DescriptionTitle> :
                    <DescriptionTitle>Human <DescriptionSpan>centered</DescriptionSpan> mobile and web solutions.</DescriptionTitle>
                }
                <DescritpionDetails>
                    We know that you and your users require elegent and usable products. The All Capps
                    end-to-end product development process is aimed to give your idea the best chance of success, however that may look.

                </DescritpionDetails>
            </DescriptionWrapper>

            <ServicesItemsWrapper>
                <div>
                    {services.map((service) => {
                        return (
                            <ServiceWrapper key={service.title}>
                                <ServiceTitle>{service.title}</ServiceTitle>
                                <ServiceContent>{service.content}</ServiceContent>
                            </ServiceWrapper>
                        )
                    })}
                    <StartProjectButton onClick={goToProcessSection}>Start your next project</StartProjectButton>
                </div>
                {isWideScreen ?
                    <DesktopImageWrapper>
                        <ServicesImage />

                    </DesktopImageWrapper> : null
                }
            </ServicesItemsWrapper>


        </HumanCenteredWrapper>
    )
};
