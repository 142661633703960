import React, { useEffect, useState } from 'react'
import { ClientType } from '../Home/ClientType/ClientType'
import { AnimatedTextWrapper, AnimatedTextWrapperPlaceholder, RestOfTitleWrapper } from '../Home/Home.style'
import { RestOfTitle } from '../Home/RestOfTitle/RestOfTitle'

export const AnimatedText = () => {

    return (
        <>
            <AnimatedTextWrapper
                type="words" // animate words or chars
                animation={{
                    x: '200px',
                    y: '-20px',
                    scale: 1.1,
                    ease: 'ease-in-out',
                }}
                animationType="lights"
                interval={0.10}
                duration={0.5}
                tag="span"
                className="animated-paragraph"
                includeWhiteSpaces
                threshold={0.1}
            >Building mobile apps for</AnimatedTextWrapper>

            <RestOfTitleWrapper>
                <ClientType />

                <RestOfTitle />
            </RestOfTitleWrapper>
        </>
    )
}

