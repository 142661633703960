import { DescriptionSpan } from '../ContactScreen/ContactScreen.style';
import { ServicesInfoWrapper } from '../ServicesScreen/ServicesScreen.style';
import { Service } from './Service/Service';
import { DescriptionsWrapper, DescriptionTitle, Header, Row, RowsWrapper, ServicesWrapper, SubTitle } from './ServicesListScreen.style';


export const ServicesListScreen = () => {

    const content = [
        {
            title: 'Discovery',
            description: 'By developing in React Native technologies allow us to build mobile apps at pace for both iOS and Android devices. Build once, deploy everywhere.',
            icon: 'camera'
        },
        {
            title: 'Design',
            description: 'Our team can build you a sophisticated, responsive site that works across mobile and desktop, just like this website.',
            icon: 'stack'
        },
        {
            title: 'Development',
            description: 'What is a car without an engine? We build robust backends that you and your users can rely on.',
            icon: 'code'
        },
        {
            title: 'UX and UI Design',
            description: 'Our designers know that an elegant user friendly platform is now non-negotiable for the best user experience and your products success.',
            icon: 'camera'
        },
        {
            title: 'Product Lifecycle Management',
            description: 'Taking a product from ideation to production can be a challenging process. We will demystify this process through managing the entire end-to-end process to get you live.',
            icon: 'code'
        },
        {
            title: 'Commercial Strategy',
            description: 'We’ll help you better understand the  musts, shoulds, and coulds to meet your desired customer impact, while ensuring positive measurable financial outcomes.',
            icon: 'stack'
        },
    ]

    return (
        <ServicesWrapper>
            <ServicesInfoWrapper>
                <DescriptionsWrapper>
                    <DescriptionTitle>
                        <Header>
                            The <DescriptionSpan>service</DescriptionSpan> we provide for you.
                        </Header>
                    </DescriptionTitle>
                    <SubTitle>Whatever the product is we can offer you full end-to-end services to get you up and running, turning your vision in to reality.</SubTitle>
                </DescriptionsWrapper>

                <RowsWrapper>
                    <Row>
                        {content.slice(0, 3).map(content => <Service key={content.title} image={content.icon} title={content.title} description={content.description} />)}
                    </Row>
                    <Row>
                        {content.slice(3, 6).map(content => <Service key={content.title} image={content.icon} title={content.title} description={content.description} />)}
                    </Row>
                </RowsWrapper>

            </ServicesInfoWrapper>


        </ServicesWrapper>
    )

}
