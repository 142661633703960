import styled from "styled-components";

export const ServicesWrapper = styled.div`
    background-color: #FFEED9;
    position: relative;
    padding: 56px 0;
    @media (min-width: 1100px) {
      padding: 112px 20px 76px 20px;
    }
`;

export const SubTitle = styled.div`
    font-size: 1em;
    line-height: 1.5;
    text-align: center;
    font-weight: 500;
    max-width: 500px;

    

    @media (min-width: 1100px) {
        font-size: 1.25em;
        line-height: 1.6;
        max-width: unset;
    }
`;

export const DescriptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 808px;
    margin: 0 32px;
    align-items: center;
    @media (min-width: 1100px) {
        margin: 0 auto;
    }
`;

export const DescriptionTitle = styled.div`
    font-size: 2em;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    max-width: 400px;

    @media (min-width: 1100px) {
        font-size: 3.375em;
        line-height: 1.29;
        max-width: unset;
    }

`;

export const Header = styled.div`
    max-width: 500px;
    margin: 0 auto 32px auto;
    
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    flex-direction: column;

    @media (min-width: 1100px) {
        flex-direction: row;
    }
`;

export const RowsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    @media (min-width: 1100px) {
        align-items: unset;
    }
`;

export const ServiceContainer = styled.div`
    display: flex;
`;

