import styled, { css } from "styled-components";
import AllCappsLogo from "../../assets/allCappsLogo.png"

export const NavigationWrapper = styled('nav')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fdd099;
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;

    z-index: 3;
    box-shadow: 0px 0px 30px -15px #111;

    @media (min-width: 992px) {
        height: 100px;

    }

    @media (min-width: 1200px) {
        padding: 0 120px;
    }
`;

export const LinksWrapper = styled('div') <{ isActive: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 56px;
    left: 0;
    margin: 0 auto;
    z-index: 100;
    opacity: ${({ isActive }) => isActive ? 1 : 0};
    transform: ${({ isActive }) => isActive ? 'translateX(0)' : 'translateX(100%)'};
    transition: 0.5s;
    background-color: #fdd099;

    @media (min-width: 992px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        width: auto;
        height: inherit;
        top: 0;
        margin: 0;
        z-index: 0;
        opacity: 1;
        transform: none;
        transition: none;
        background-color: #fdd099;
    }

    @media (min-width: 1200px) {
        background-color: #fdd099;
    }
`;

export const LinkElem = styled('div') <{ isActive: boolean }>`
    padding: 20px;
    font-size: 1.25em;
    font-weight: 700;
    color: #2E2D27;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;

    ::after {
        display: block;
        margin: 0 auto;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: #cba87e;
        opacity: 0;
    }
    :hover {
        ::after { 
            opacity: 1;
        }
    }

    ${({ isActive }) => isActive ? css`
        ::after {
            display: block;
            margin: 0 auto;
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: #cba87e;
            opacity: 1;
        }
    ` : ''};

    @media (min-width: 1200px) {
        padding: 34px;
    }

`;

export const LogoWrapper = styled('div')`
`;

export const CallToActionButtonWrapper = styled('div')`
    background-color: #E83F3B;
    border-radius: 4px;
    color: #fff;
    padding: 10px 14px;
    font-weight: 700;
    height: 24px;
    align-items: center;
    display: flex;
    cursor: pointer;
`;

export const LogoImg = styled('div')`
    width: 90px;
    height: 40px;
    background: url(${AllCappsLogo});
    background-size: contain;

    @media (min-width: 992px) {
        width: 180px;
        height: 80px;
    }
`;

export const Hamburger = styled('div')`
    display: block;
    width: 33px;
    cursor: pointer;
    
    @media (min-width: 992px) {
        display: none;
    }
`;

export const Bar = styled('div') <{ count: number, isActive: boolean }>`

    width: 100%;
    height: 3px;
    display: block;
    color: #292d32;
    padding: 0;
    margin: 6px 0;
    transition: 0.5s;
    background-color: #292d32;
    ${({ count }) => count == 2 ? 'opacity: 0.6' : ''};

    ${({ isActive, count }) => isActive ? css`
        width: 100%;
        transform: ${count === 1 ? 'translateY(8px) rotate(-315deg)' : count === 3 ? 'translateY(-10px) rotate(-45deg)' : ''};
        opacity: ${count === 2 ? 0 : 1};
    ` : ''
    }
`;

export const BasicLinksWrapper = styled('div')`
    display: flex;
    flex-direction: column;

@media(min-width: 992px) {
    flex-direction: row;
    margin: 0 auto;
}
`;
