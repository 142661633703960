import React, { useContext, useEffect, useState } from 'react'; // we need this to 
import AnimatedText from 'react-animated-text-content';
import { AnimatedTextWrapper, AnimatedTextWrapperLast, PlaceholderTitle } from '../Home.style';

interface PropsTypes {
    aboutRef: React.RefObject<HTMLDivElement>,
}

export const RestOfTitle = () => {
    const [showRestOfTitle, setShowRestOfTitle] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowRestOfTitle(true);
        }, 1000)
    }, [])

    return (

        <AnimatedTextWrapperLast
            type="words" // animate words or chars
            animation={{
                x: '200px',
                y: '-20px',
                scale: 1.1,
                ease: 'ease-in-out',
            }}
            animationType="lights"
            interval={0.10}
            duration={0.5}
            tag="span"
            className="animated-paragraph"
            includeWhiteSpaces
            threshold={0.1}

            show={showRestOfTitle}
        >
            with emphasis.

        </AnimatedTextWrapperLast>
        // : <PlaceholderTitle>with emphasis.</PlaceholderTitle>

    )
}