import React, { useContext } from 'react'; // we need this to make JSX compile
import useWindowDimensions from '../../context/windowDimensions/WindowDimensions';

import { 
    InfoScreenWrapper,
    TeamImage,
    DescriptionsWrapper,
    DescriptionTitle,
    Header,
    DescriptionSpan,
    SubTitle
} from './InfoScreen.style';

export const InfoScreen = () => {
    const { width } = useWindowDimensions();
    const widthOfPattern = width / 6;
    return (
        <InfoScreenWrapper width={widthOfPattern}>
            <DescriptionsWrapper>
                <DescriptionTitle>
                    <Header>
                        We <DescriptionSpan>energise</DescriptionSpan> ideas by unlocking digital opportunities.
                    </Header>
                </DescriptionTitle>
                <SubTitle>All Capps is a mobile and web development agency. We work with some of the world’s most ambitious businesses and founders in technology, product, and commercial services.</SubTitle>
            </DescriptionsWrapper>
            <TeamImage />
        </InfoScreenWrapper>
    )
};
