import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../context/windowDimensions/WindowDimensions";
import { MainScreenType } from "../MainScreen/MainScreen";
import {
    NavigationWrapper,
    LinksWrapper,
    LogoWrapper,
    CallToActionButtonWrapper,
    LinkElem,
    LogoImg,
    Hamburger,
    Bar,
    BasicLinksWrapper
} from "./TopNavigation.styles"

const getDimensions = (ele: HTMLDivElement) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom,
    };
};

export const TopNavigation = ({ homeRef, aboutRef, servicesRef, processRef, humanCenteredRef }: MainScreenType) => {
    const headerRef = useRef<HTMLDivElement>(null);
    const [isActiveBurger, setIsActiveBurger] = useState(false);
    const [visibleSection, setVisibleSection] = useState<string | null>(null);
    console.log('visible section', visibleSection);
    const { width } = useWindowDimensions();

    const sections = [
        {
            section: 'home',
            ref: homeRef,
        },
        {
            section: 'about',
            ref: aboutRef,
        },
        {
            section: 'services',
            ref: servicesRef,
        },
        {
            section: 'humanCentered',
            ref: humanCenteredRef,
        },
        {
            section: 'process',
            ref: processRef,
        }
    ]

    useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current === null) {
                return
            }

            const { height: headerHeight } = getDimensions(headerRef.current);
            const scrollPosition = window.scrollY + headerHeight + 1;

            const selected = sections.find(({ ref }) => {
                const ele = ref.current;
                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    console.log("scrollPosition ", scrollPosition, " offsetTop ", offsetTop, " offsetBottom ", offsetBottom)
                    return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                }
            });
            if(selected === undefined) {
                setVisibleSection('home');
            }
            if (selected && selected.section !== visibleSection) {
                setVisibleSection(selected.section);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [visibleSection]);

    const goToHomeSection = () => {
        const offsetTop = homeRef?.current?.offsetTop ?? 100;
        const isTabletOrWider = (width >= 992);

        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
        setIsActiveBurger(false);
    }
    const goToAboutSection = () => {
        const offsetTop = aboutRef?.current?.offsetTop ?? 100;
        const isTabletOrWider = (width >= 992);
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
        setIsActiveBurger(false);
    }
    const goToServicesSection = () => {
        const offsetTop = servicesRef?.current?.offsetTop ?? 100;
        const isTabletOrWider = (width >= 992);
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
        setIsActiveBurger(false);
    }
    const goToHumanCenteredSection = () => {
        const offsetTop = humanCenteredRef?.current?.offsetTop ?? 100;
        const isTabletOrWider = (width >= 992);
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
        setIsActiveBurger(false);
    }
    const goToProcessSection = () => {
        const offsetTop = processRef?.current?.offsetTop ?? 100;
        const isTabletOrWider = (width >= 992);
        window.scrollTo({
            top: offsetTop - (isTabletOrWider ? 100 : 56),
            behavior: 'smooth'
        });
        setIsActiveBurger(false);
    }
    const toggleBurger = () => {
        setIsActiveBurger(prev => !prev)
    }


    return (
        <NavigationWrapper ref={headerRef}>
            <LogoWrapper>
                <LogoImg />
            </LogoWrapper>
            <LinksWrapper isActive={isActiveBurger}>
                <BasicLinksWrapper>
                    <LinkElem onClick={goToHomeSection} isActive={visibleSection === 'home'}>Home</LinkElem>
                    <LinkElem onClick={goToAboutSection} isActive={visibleSection === 'about'}>About</LinkElem>
                    <LinkElem onClick={goToServicesSection} isActive={visibleSection === 'services'}>Services</LinkElem>
                    <LinkElem onClick={goToHumanCenteredSection} isActive={visibleSection === 'humanCentered'}>Process</LinkElem>
                </BasicLinksWrapper>
                <CallToActionButtonWrapper onClick={goToProcessSection}>
                    Start your project
                </CallToActionButtonWrapper>
            </LinksWrapper>

            <Hamburger onClick={toggleBurger}>
                <Bar count={1} isActive={isActiveBurger} />
                <Bar count={2} isActive={isActiveBurger} />
                <Bar count={3} isActive={isActiveBurger} />
            </Hamburger>
        </NavigationWrapper>
    )
}