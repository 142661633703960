import React, { useContext, useRef, useState } from 'react';
import GlobalState from '../../context/GlobalState';
import { NextStepButton, ButtonsWrapper, FormWrapper, Input, Label, StepOneTitle, StepOneWrapper, StepTwoWrapper, TextArea, Wrapper, SubmitButton, ErrorMessage, SentInfo, SentText, SentButtonsWrapper, NextProjectButton, TickIconWrapper } from './ContactForm.style';
import emailjs from '@emailjs/browser';
import { InputWrapper } from '../../screens/ContactScreen/ContactScreen.style';
import { validateEmail } from './helpers';
import { TickIcon } from '../../assets/icons/TickIcon';
import { Loader } from '../Loader/Loader';
import { Button } from '../CommonComponents.style';

export const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
        jobTitle: "",
        company: "",
        country: "",
        budget: "",
        details: ""
    });

    const [step, setStep] = useState('first');
    const [emailError, setEmailError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSentMessage, setIsSentMessage] = useState(false);


    const checkEmail = (email: string): void => {
        const emailCheck = email !== '' && validateEmail(email) === null;
        setEmailError(emailCheck)
    }

    const clear = (): void => {
        setFormData({
            name: "",
            lastName: "",
            phone: "",
            email: "",
            message: "",
            jobTitle: "",
            company: "",
            country: "",
            budget: "",
            details: ""
        });
    }


    const isValidFirstStep = formData.name !== "" && formData.lastName !== "" && formData.email !== "" && emailError === false;
    const isValidSecondStep = formData.budget !== "";
    const setNextStep = () => {
        if (isValidFirstStep) {
            setStep("second");
        }
    }

    const setNewProject = () => {
        setStep("first");
        setIsSentMessage(false);
        clear();
    }

    const sendEmail = (e: any) => {
        setIsLoading(true);
        e.preventDefault();
        var templateParams = {
            first_name: formData.name,
            last_name: formData.lastName,
            project_details: formData.details,
            phone: formData.phone,
            email: formData.email,
            job_title: formData.jobTitle,
            company: formData.company,
            country: formData.country,
            budget: formData.budget,
        };


        emailjs.send(process.env.REACT_APP_SERVICE_ID ?? '', process.env.REACT_APP_TEMPLATE_ID ?? '', templateParams, process.env.REACT_APP_USER_ID ?? '')
            .then((result) => {
                console.log(result.text);
                setIsSentMessage(true);
                setIsLoading(false);

            }, (error) => {
                console.log(error.text);
            });
    };


    return (
        <Wrapper>
            <StepOneWrapper step={step}>
                <div>
                    <StepOneTitle>
                        Step 1: About you
                    </StepOneTitle>
                    <FormWrapper>
                        <InputWrapper>
                            <Label>First Name*</Label>
                            <Input onChange={(a) => setFormData({ ...formData, name: a.target.value })} value={formData.name} />
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Last Name*</Label>
                            <Input onChange={(a) => setFormData({ ...formData, lastName: a.target.value })} value={formData.lastName} />
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Email*</Label>
                            <Input type="email" error={emailError} onBlur={(a) => checkEmail(a.target.value)} onChange={(a) => setFormData({ ...formData, email: a.target.value })} value={formData.email} />
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Phone number</Label>
                            <Input type="number" onChange={(a) => setFormData({ ...formData, phone: a.target.value })} value={formData.phone} />
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Job Title</Label>
                            <Input onChange={(a) => setFormData({ ...formData, jobTitle: a.target.value })} value={formData.jobTitle} />
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Company</Label>
                            <Input onChange={(a) => setFormData({ ...formData, company: a.target.value })} value={formData.company} />
                        </InputWrapper>
                        <InputWrapper>
                            <Label>Country</Label>
                            <Input onChange={(a) => setFormData({ ...formData, country: a.target.value })} value={formData.country} />
                        </InputWrapper>
                        <NextStepButton isValid={isValidFirstStep} onClick={setNextStep}>Next project details</NextStepButton>
                        {emailError ? <ErrorMessage>Check email address</ErrorMessage> : null}

                    </FormWrapper>
                </div>
            </StepOneWrapper>
            <StepTwoWrapper step={step}>
                <StepOneTitle>
                    Step 2: Project details
                </StepOneTitle>
                <FormWrapper>
                    <InputWrapper>
                        <Label isDisabled={isSentMessage}>Project details</Label>
                        <TextArea onChange={(a) => setFormData({ ...formData, details: a.target.value })} value={formData.details} disabled={isSentMessage} />
                    </InputWrapper>
                    <InputWrapper>
                        <Label isDisabled={isSentMessage}>Approximate budget*</Label>
                        <Input onChange={(a) => setFormData({ ...formData, budget: a.target.value })} value={formData.budget} disabled={isSentMessage} />
                    </InputWrapper>

                    <ButtonsWrapper isSent={isSentMessage} step={step}>
                        <Button onClick={() => setStep("first")} >Back to about you</Button>
                        <SubmitButton onClick={sendEmail} isValid={isValidSecondStep} >
                            {isLoading ?
                                <Loader spinnerColor="#FFEED9" /> :
                                'Submit project'}

                        </SubmitButton>
                    </ButtonsWrapper>

                    <SentButtonsWrapper isSent={isSentMessage} step={step}>
                        <SentInfo>
                            <TickIconWrapper  >
                                <TickIcon color="#FCC800"/>
                            </TickIconWrapper>
                            <SentText>Sent! We will be in touch soon.</SentText>
                        </SentInfo>
                        <NextProjectButton isValid={true} onClick={setNewProject}>Start next project</NextProjectButton>
                    </SentButtonsWrapper>


                </FormWrapper>
            </StepTwoWrapper>

        </Wrapper>
    )
}
