import styled from "styled-components";
import AllCappsLogo from "../../assets/allCappsLogo.png"
import { LocationIcon } from "../../assets/icons/LocationIcon";

interface RowType {
    isFirst?: boolean;
}

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Poppins',sans-serif;
    padding: 40px 15px 22px 15px;
   
    @media (min-width: 768px) {
        padding: 40px 0 56px 0;
        margin: 0 auto;
        max-width: 916px;
    }
    
`;

export const Row = styled.div<RowType>`
    display: flex;
    justify-content: space-between;
    align-items: center;
 
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
        ${({ isFirst }) => isFirst === true ? ' padding: 0 20px' : 'padding: 0'};
    }
`;

export const LogoWrapper = styled.div`
    background: url(${AllCappsLogo});
    height: 56px;
    min-width: 123px;
    background-size: contain;
`;

export const Links = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (min-width: 768px) {
        margin-left: 176px;
    }

`;

export const Link = styled.div`
    font-size: 0.875em;
    margin-top: 32px;
    line-height: 1.29;
    font-weight: bold;
    cursor: pointer;

    @media (min-width: 768px) {
        margin-right: 38px;
        margin-top: 0;
        &:last-of-type { 
            margin-right: 0px;
        }
    }

   
`;

export const LineSeparator = styled.div`
    height: 16px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 16px;
`;

export const AddressWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-of-type { 
        margin-top: 8px;
    }

    @media (min-width: 768px) {
        margin-right: 48px;
        &:last-of-type { 
            margin-right: 0;
            margin-top: 0;
        }
    }

`;

export const Address = styled.div`
    font-size: 0.75em;
    line-height: 1.33;
    font-weight: bold; 
    color: rgba(0, 0, 0, 0.56);
    margin-left: 4px;
`;

export const GeoIcon = styled(LocationIcon)`
`;