import styled from "styled-components";


export interface ButtonType {
    isValid?: boolean;
}

export const Button = styled.div<ButtonType>`
    border: 3px solid #E83F3B;
    border-radius: 4px;
    color: #E83F3B;
    padding: 14px 10px;
    font-size: 1em;
    line-height: 20px;
    cursor: pointer;
    font-family: 'Poppins',sans-serif;
    width: 100%;
    text-align: center;

`;